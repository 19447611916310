export default [
    {
        name: 'makeMoneyTopUp',
        path: "/makeMoney/topUp",
        component: () => import('../views/makeMoney/top-up'),
        meta: {
            name: '氪金'
        }
    },
    {
        name: 'makeMoneyMining',
        path: "/makeMoney/mining",
        component: () => import('../views/makeMoney/mining'),
        meta: {
            name: '挖矿'
        }
    },
    {
        name: 'makeMoneyCatchFish',
        path: "/makeMoney/catchFish",
        component: () => import('../views/makeMoney/catch-fish'),
        meta: {
            name: '炸鱼'
        }
    },
    {
        name: 'makeMoneyHangUp',
        path: "/makeMoney/hangUp",
        component: () => import('../views/makeMoney/hang-up'),
        meta: {
            name: '挂机'
        }
    },
    {
        name: 'makeMoneyFarm',
        path: "/makeMoney/farm",
        component: () => import('../views/makeMoney/farm'),
        meta: {
            name: '种地'
        }
    }
]