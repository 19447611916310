// 是否包含目标
function hasTag(v, tag) {
    if (!v) {
        return false
    }
    if (typeof v === 'string') {
        // 排除图片的影响
        if (v.indexOf('data:image') === 0) {
            return false
        }
        return v.indexOf(tag) > -1;
    } else if (typeof v === 'object') {
        for (let i in v) {
            if (hasTag(v[i], tag)) {
                return true;
            }
        }
    } else {
        return false;
    }
}

const utils = {
    hasTag
}

import Vue from "vue";

Vue.use({
    install(Vue) {
        Vue.prototype.utils = utils;
    }
})
