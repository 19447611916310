export default [
    {
        name: 'mainCommon',
        path: "/main/common",
        component: () => import('../views/main/common'),
        meta: {
            name: '菜单和属性'
        }
    },
    {
        name: 'mainEquip',
        path: "/main/equip",
        component: () => import('../views/main/equip'),
        meta: {
            name: '装备图鉴'
        }
    },
    {
        name: 'mainMachine',
        path: "/main/machine",
        component: () => import('../views/main/machine'),
        meta: {
            name: '学习机'
        }
    },
    {
        name: 'prop',
        path: "/main/prop",
        component: () => import('../views/main/prop'),
        meta: {
            name: '道具图鉴'
        }
    },
    {
        name: 'ling',
        path: "/main/ling",
        component: () => import('../views/main/ling'),
        meta: {
            name: '灵根和官阶'
        }
    },
    {
        name: 'altar',
        path: "/main/altar",
        component: () => import('../views/main/altar'),
        meta: {
            name: '祭坛'
        }
    }
]