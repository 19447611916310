<template>
    <div class="nav">
        <ul>
            <template v-for="(v,i) in routes">
                <li v-if="v.children && v.children.length > 0" @mouseenter="v.show = true" @mouseleave="v.show = false">
                    <div class="name" @click="goto(v)">{{ v.name }}</div>
                    <div class="nav-children" :class="{'show': v.show}">
                        <ul>
                            <template v-for="child in v.children">
                                <li @click.stop="goto(child)">{{ child.name }}</li>
                            </template>
                        </ul>
                    </div>
                </li>
                <li v-if="!v.children || v.children.length === 0">
                    <div class="name" @click="goto(v)">{{ v.name }}</div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import {routes} from '../router/index'

export default {
    name: "NavHeader",
    data() {
        return {
            routes: routes
        }
    },
    methods: {
        goto(v) {
            if (this.$route.name === v.routeName) {
                return;
            }
            this.show = false;
            this.$router.push({
                name: v.routeName
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.nav {
    flex: 1 0 auto;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    line-height: 40px;
    position: relative;

    & > ul {
        margin: 0;
        padding: 0;
        list-style: none;

        & > li {
            display: inline-block;
            width: 120px;
            letter-spacing: 1px;
            padding: 20px 0;
            cursor: pointer;
            transition: color 0.3s ease-in;
            position: relative;

            .name {
                text-align: center;
            }

            &:hover {
                color: #f2f3f5;
            }
        }
    }


    .nav-children {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 110%;
        left: 0;
        background-color: rgba(88, 88, 88, 1);
        transition: all .3s ease-out;
        box-sizing: border-box;
        padding: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.15);

        &.show {
            visibility: visible;
            top: 100%;
            opacity: 1;
        }

        ul {
            width: 120px;
            margin: 0;
            padding: 0 0 0 18px;
            box-sizing: border-box;
            list-style: none;

            li {
                font-size: 14px;
                white-space: nowrap;
                cursor: pointer;
                line-height: 32px;

                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {

    .nav {
        display: none;
    }

}
</style>