<template>
    <div id="app" ref="app">
        <div class="header">
            <div class="container">
                <div class="nav-btn" @click="goto({routeName:'nav'})">
                    <svg class="icon"
                         style="width: 2em;height: 2em;vertical-align: middle;fill: currentColor;overflow: hidden;"
                         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3576">
                        <path d="M376.832 474.112H130.048c-33.792 0-61.44-27.648-61.44-61.44V165.888c0-33.792 27.648-61.44 61.44-61.44h247.296c33.792 0 61.44 27.648 61.44 61.44v247.296c-0.512 33.792-27.648 60.928-61.952 60.928zM130.048 165.888v247.296h247.296V165.888H130.048zM739.84 525.312c-16.384 0-31.744-6.144-43.52-17.92L521.728 332.8c-11.776-11.776-17.92-27.136-17.92-43.52s6.144-31.744 17.92-43.52L696.32 71.168c11.776-11.776 27.136-17.92 43.52-17.92s31.744 6.144 43.52 17.92L957.952 245.76c11.776 11.776 17.92 27.136 17.92 43.52s-6.144 31.744-17.92 43.52L783.36 507.392c-11.776 11.776-27.136 17.92-43.52 17.92z m0-411.136l-174.592 174.592 174.592 174.592 174.592-174.592-174.592-174.592zM376.832 960.512H130.048c-33.792 0-61.44-27.648-61.44-61.44v-247.296c0-33.792 27.648-61.44 61.44-61.44h247.296c33.792 0 61.44 27.648 61.44 61.44v247.296c-0.512 34.304-27.648 61.44-61.952 61.44z m-246.784-308.224v247.296h247.296v-247.296H130.048zM863.232 960.512h-247.296c-33.792 0-61.44-27.648-61.44-61.44v-247.296c0-33.792 27.648-61.44 61.44-61.44h247.296c33.792 0 61.44 27.648 61.44 61.44v247.296c0 34.304-27.136 61.44-61.44 61.44z m-246.784-308.224v247.296h247.296v-247.296h-247.296z"
                              p-id="3577"></path>
                    </svg>
                </div>
                <div class="logo" @click="goto({routeName: '/'})">多彩饥荒</div>
                <nav-header :routes="routes"></nav-header>
            </div>
        </div>
        <div class="content">
            <div ref="content" class="container">
                <router-view/>
            </div>
            <page-footer/>
        </div>
    </div>
</template>

<script>

import NavHeader from "@/components/NavHeader";
import PageFooter from "@/components/Footer";

export default {
    name: 'App',
    components: {
        NavHeader,
        PageFooter
    },
    data() {
        return {
            routes: [],
        }
    },
    methods: {
        goto(v) {
            if (this.$route.name === v.routeName) {
                return;
            }
            this.$router.push({
                name: v.routeName
            });
        }
    },
    mounted() {
        // 解决屏幕未占满问题
        this.$refs.content.style.minHeight = document.documentElement.clientHeight - 450 + 'px';
    },
    watch: {
        '$route': function(to, from) {
            this.$refs.app.scrollTop = 0
        }
    }
}
</script>

<style lang="scss" scoped>
#app {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: rgba(240, 240, 240, 0.75);
    font-size: 14px;
    font-family: Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB, serif;
    background-image: url("./assets/images/bg/bg.webp");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: #19110d;
    overflow-y: auto;
}

.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    color: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    background-color: #585858;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 2;

    .container {
        display: flex;

        .nav-btn {
            display: none;
            position: absolute;
            top: 0px;
            left: 0px;
            padding: 25px 15px;
        }

        .logo {
            text-align: center;
            padding: 20px 30px;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: 2px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.75);
            border-right: 1px solid rgba(0,0,0,.3);
            cursor: pointer;
        }



        @media screen and (max-width: 768px) {

            .nav-btn{
                display: block;
            }

            .logo{
                border: none;
                width: 100%;
            }

        }

    }
}

.content {
    box-sizing: border-box;
    padding-top: 20px;
}

</style>


<!--简介-->

<!--仙服内容：{-->

<!--世界配置-->

<!--装备图鉴-->

<!--武器锻造-->

<!--打宝爆率-->

<!--灵根升级-->

<!--抽奖: {-->

<!--模拟抽奖-->

<!--}-->

<!--袋子奖品: {-->

<!--模拟开袋-->

<!--}-->

<!--学习机-->

<!--}-->

<!--新手攻略-->

<!--升级-->

<!--获取猫头币-->

<!--常见问题-->

<!--更新日志-->